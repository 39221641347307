import React from "react";
import {graphql, StaticQuery, Link} from 'gatsby';
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import TopBlueSection from '../components/TopBlueSection';
import BasicText from '../components/BasicText';

const NotFoundPage = () => (
 
  <StaticQuery query={graphql`
  {
    allGlobalsJson {
      edges {
        node {
          _404_page_top_section_text
          content_404
        }
      }
    }
  }  
  `} render={props =>(
    <Layout>
      <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      >
        <meta charSet="utf-8" />
        <title>MyRetirement | Page not found</title>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="c71e0e36-b367-4b3d-a1ba-3f53aed3fc7b-test" ></script>
        <script type="text/javascript">
        function OptanonWrapper() { }
        </script>
      </Helmet>
      <div id="main" className="error-page-content">
        <TopBlueSection 
            wrapperClass="site-container"
            sectionText={props.allGlobalsJson.edges[0].node._404_page_top_section_text}
        />
        <BasicText 
          wrapperClass="site-container"
          sectionText={props.allGlobalsJson.edges[0].node.content_404}
        />
        <section className="back-to-home-wrap">
          <div className="site-container">
            <Link to="/">Go to the homepage</Link>
          </div>
        </section>
      </div>
    </Layout>
  )} />
  
)

export default NotFoundPage
